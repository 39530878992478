import { useRouter } from 'next/router';

export const useCanonicalLink = (addBasePath = true, pagePath?: string, lang?: string) => {
  const router = useRouter();
  const asPath = pagePath || router.asPath;
  const locale = lang || router.locale;

  const localeWithoutDefault = locale === 'en' ? '' : `/${locale}`;

  const [pathWithoutQueryParmas] = asPath.split(/[?#]/);

  const basePath = process.env.NEXT_PUBLIC_BASE_URL || 'https://www.turing.com';

  const path = `${localeWithoutDefault}${
    asPath === '/' ? '' : pathWithoutQueryParmas
  }`;

  if (addBasePath) {
    return `${basePath}${path}`;
  }

  return path;
};
