import Head from 'next/head';
import { useCanonicalLink } from 'ui/hooks/useCanonicalLink';

const CanonicalLink = ({ custom: customCanonicalLink }) => {
  const canonicalLink = useCanonicalLink();

  const href = customCanonicalLink || canonicalLink;

  return (
    <Head>
      <link rel="canonical" href={href} />
    </Head>
  );
};

export default CanonicalLink;
