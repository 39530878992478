import Head from 'next/head';
import { useRouter } from 'next/router';
import { isArrayNotEmpty } from 'ui/utils/helpers/array.helper';
import { useCanonicalLink } from 'ui/hooks/useCanonicalLink';
import CanonicalLink from 'ui/components/CanonicalLink/CanonicalLink';
import { getContentTypeMetaValue, getGtmCategoryMetaValue, getPageScoreMetaValue, mapOpenGraphTags } from './openGraphCMS.helper';

const OpenGraphCMS = ({ SEO, imageUrl = undefined }) => {
  const {
    unicodeTitle: seoUnicodeTitle,
    title,
    description,
    keywords,
    og_tags = [],
    rich_snippets = [],
    no_index = false,
    canonical_url = null,
    og_image = null,
    publication_date = null,
  } = SEO || {};

  const { locale, pathname, asPath } = useRouter();
  const contentTypeValue = getContentTypeMetaValue(asPath || pathname);
  const gtmCategory = getGtmCategoryMetaValue(asPath || pathname);
  const pageScore = getPageScoreMetaValue(asPath || pathname);
  const canonicalLink = useCanonicalLink();
  const unicodeTitle = seoUnicodeTitle || title;

  const ogTags = mapOpenGraphTags({
    title: unicodeTitle,
    description,
    og_tags,
  });

  const isSEMPages =
    pathname.includes('/lp/') ||
    pathname.includes('/services/campaign') ||
    asPath?.split('?')[0] === '/services/campaign';

  const defaultImageUrlForOgTags = `${typeof window !== 'undefined' ? window.location.origin : process.env.NEXT_PUBLIC_BASE_URL
    }/img/og_image.png`;

  return (
    <>
      <Head>
        <meta name="twitter:card" content="summary_large_image" />
        {unicodeTitle && <title key="title">{unicodeTitle}</title>}
        {unicodeTitle && <meta name="twitter:title" content={unicodeTitle} />}
        {description && <meta name="description" content={description} />}
        {description && <meta name="twitter:description" content={description} />}
        {keywords && <meta name="keywords" content={keywords} />}

        {ogTags.map(ogTag => (
          <meta key={ogTag.name} property={ogTag.name} content={ogTag.content} />
        ))}

        <meta key="og:url" property="og:url" content={canonicalLink} />
        <meta name="twitter:site" content={canonicalLink} />
        {/* // meta tags for elastic search */}
        <meta key="og:image" property="og:image" content={og_image?.url || defaultImageUrlForOgTags} class="elastic" name="og_image" />

        {contentTypeValue && <meta class="elastic" name="content_type" content={contentTypeValue} />}
        <meta name='lang' content={locale || 'en'} class="elastic" />
        {publication_date && <meta name='article_date' content={new Date(publication_date).toISOString().slice(0, 10)} class="elastic" />}
        {gtmCategory && <meta name='gtm_category' content={gtmCategory} class="elastic" />}
        {pageScore && <meta class="elastic" name="content_score" content={pageScore?.score || 1} />}
        {/* // */}
        <meta name="twitter:image" content={og_image?.url || defaultImageUrlForOgTags} />

        {isArrayNotEmpty(rich_snippets) &&
          rich_snippets.map(({ structured_data } = {}, index) => (
            <script
              key={index}
              type="application/ld+json"
              defer
              dangerouslySetInnerHTML={{
                __html: JSON.stringify(structured_data),
              }}
            />
          ))}
        {no_index && <meta name="robots" content="noindex" />}
      </Head>

      {!isSEMPages && <CanonicalLink custom={canonical_url} />}
    </>
  );
};

export default OpenGraphCMS;
