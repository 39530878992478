export const mapOpenGraphTags = ({ title, description, og_tags }) => {
  const ogTagsToDefaultValueMapping = {
    'og:title': title,
    'og:description': description,
  };

  og_tags = og_tags.map(tag => ({ ...tag, name: `og:${tag.name}` }));

  Object.keys(ogTagsToDefaultValueMapping).forEach(ogKey => {
    const isTagPresent = og_tags.some(tag => tag.name === ogKey);
    if (!isTagPresent) {
      og_tags.push({
        name: ogKey,
        content: ogTagsToDefaultValueMapping[ogKey],
      });
    }
  });

  return og_tags;
};


const contentTypeMappings = {
  '/blog/': 'Blog',
  '/resources/': 'Article',
  '/jobs/': 'Job',
  '/remote-developer-jobs': 'Job',
  '/interview-questions': 'Interview Question',
  '/services/blog': 'Use Case'
};

// Function to get content type meta value
export const getContentTypeMetaValue = (pathname) => {
  const regex = /^\/blog(?:-copy)?(?:\/(?:category(?:\/[^\/]*)?)?)?\/?$/;
  if (regex.test(pathname)) {
    return null;
  }
  
  for (const path in contentTypeMappings) {
    if (pathname.startsWith(path)) {
      return contentTypeMappings[path];
    }
  }
  return null;
};

// Function to get GTM category meta value
export const getGtmCategoryMetaValue = (pathname) => {
  const categoryMappings = [
    { pattern: /^\/blog(?:\/.*)?/, category: 'Demand,Talent' },
    { pattern: /^\/(company|press|policy|terms-of-service)$/, category: 'Demand,Talent' },
    { pattern: /^(\/|\/hire-developers|\/use-cases)$/, category: 'Demand' },
    { pattern: /^\/(case-studies|services\/blog|services(?:\/.*)?|resources(?:\/.*)?|hire(?:\/.*)?)$/, category: 'Demand' },
    { pattern: /^\/(interview-questions|jobs|remote-developer-jobs|kb)(?:\/.*)?$/, category: 'Talent' }
  ];

  // Check patterns and return corresponding category
  for (const { pattern, category } of categoryMappings) {
    if (pattern.test(pathname)) {
      return category;
    }
  }

  // Return default category if no pattern matches
  return 'Demand,Talent';
}

export const getPageScoreMetaValue = (pathname) => {
  const pathScores = {
    '/resources/.*': { category: 'Article', score: 3 },
    '/case-study/.*': { category: 'Case Study', score: 5 },
    '/blog/.*': { category: 'Blog', score: 2 },
    '/jobs/.*': { category: 'Job listing', score: 1 },
    '/remote-developer-jobs/.*': { category: 'Job listing', score: 1 },
    '/interview-questions/.*': { category: 'Interview Question', score: 2 },
    '/kb/.*': { category: 'Knowledge base', score: 1 },
    '/white-paper/.*': { category: 'White Paper', score: 5 },
    '/guide/.*': { category: 'Guides', score: 5 },
    '/video/.*': { category: 'Videos', score: 3 },
    '/webinar/.*': { category: 'Webinars', score: 5 },
    '/services/.*': { category: 'Services', score: 4 }
  };

  for (const pattern in pathScores) {
    const regex = new RegExp(pattern);
    if (regex.test(pathname)) {
      return pathScores[pattern];
    }
  }

  return 1;
}